.CaseStudiesPage {
  table {
    td {
      &.responded {
        background: $table-success;
      }
      &.notResponded {
        background: $table-warning;
      }
    }
  }
}
