.Copyright {
  background-color: $grey;
  padding: 1rem;
  text-align: center;
  p {
    color: white;
    font-size: 1.2rem;
    margin-bottom: 0rem;
  }
  a {
    color: $secondary;
  }
}
