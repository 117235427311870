.Top {
  // text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  ul {
    margin: 0;
    list-style-type: none;
    padding-left: 0;

    li {
      display: inline-block;
      margin-right: 1rem;

      a {
        text-transform: lowercase;
        color: white;
        font-size: 1.6rem;

        @include media-breakpoint-up(md) {
          font-size: 1.4rem;
        }

        @include media-breakpoint-up(lg) {
          font-size: 1.6rem;
        }

        font-weight: 600;
        transition: opacity 0.2s;

        &.current {
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        }

        &:hover {
          text-decoration: none;
          opacity: 0.7;
        }
      }
    }
  }

  .menu-link {
    right: 1rem;
    top: 1rem;

    &:focus {
      outline: none;
    }

    .hamburger-inner {
      background-color: white;

      &::before,
      &::after {
        background-color: white;
      }
    }
  }
}

.mobile-navigation {
  display: none;
  position: fixed;
  top: 0;
  height: 100%;
  background: blue;
}
