.Footer {
  padding-top: 1em;
  background: $grey-blue;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0 0 1em 0;

    li {
      display: inline-block;
      a {
        color: white;
        font-size: 1.2em;
        margin-right: 0.5em;

        &.current {
          text-decoration: underline;
        }
      }
    }
  }
}
