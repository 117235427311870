.HandbookChapterPage {
  .Content {
    .Wrapper {
      background: white;
      padding-top: 1rem;
      margin-bottom: 1rem;

      > div:not(:last-child) {
        border-bottom: 1px dashed $border;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
      }

      a,
      p,
      li,
      table {
        font-size: 1.4rem;
      }

      h5 {
        font-weight: 500;
      }

      ol.letter {
        list-style-type: lower-alpha;
      }

      ol.start {
        counter-reset: yourCounter;
      }

      ol.start,
      ol.continue {
        list-style: none;
        > li:before {
          content: counter(yourCounter) ". ";
          counter-increment: yourCounter;
        }
      }

      table {
        width: 100% !important;
        border: 1px solid $border;
        margin-bottom: 1rem;
        tr {
          td {
            border-bottom: 1px solid $border;
            padding: 0.5rem;
          }

          td:not(:last-child) {
            border-right: 1px solid $border;
          }
        }
      }
    }
  }

  .Footnotes {
    padding-top: 1em;
    padding-bottom: 1em;
    border-top: 1px solid $border;

    p {
      font-size: 1.1em;
      margin-bottom: 0;
      color: $grey;
    }
  }
}
