.HandbookPage {
  .TableOfContents {
    background: white;
    padding: 1rem;

    h5 {
      font-weight: 500;
    }

    ol {
      > li {
        font-size: 1.4rem;
        a {
          font-weight: 600;
          color: $primary;
          transition: color 0.2s;

          &:hover {
            color: $bright-blue;
            text-decoration: none;
          }
        }
      }
      > ul {
        list-style-type: lower-roman;
        > li {
          font-size: 1.4rem;

          a {
            transition: color 0.2s;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .SearchResults {
    list-style-type: none;
    padding-left: 0;

    li {
      padding: 1rem 1rem 0 1rem;
      background: white;
      border: 1px solid $border;

      > a {
        font-weight: 600;
        font-size: 1.6rem !important;
      }

      a,
      p {
        font-size: 1.4rem;
      }
    }

    li:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
