@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap");

html {
  font-size: 62.5%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
li {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 300;
}
h1 {
  font-size: 4rem;
  line-height: 1.2;
}
h2 {
  font-size: 3.6rem;
  line-height: 1.25;
}
h3 {
  font-size: 3rem;
  line-height: 1.3;
}
h4 {
  font-size: 2.4rem;
  line-height: 1.35;
}
h5 {
  font-size: 1.8rem;
  line-height: 1.5;
}
h6 {
  font-size: 1.5rem;
  line-height: 1.6;
}

/* Larger than phablet */
@include media-breakpoint-up(sm) {
  h1 {
    font-size: 5rem;
  }
  h2 {
    font-size: 4.2rem;
  }
  h3 {
    font-size: 3.6rem;
  }
  h4 {
    font-size: 3rem;
  }
  h5 {
    font-size: 2.4rem;
  }
  h6 {
    font-size: 1.5rem;
  }
}

p {
  font-size: 1.4rem;
  margin-top: 0;
}
