.CaseStudyPage {
  .CaseStudy {
    margin-bottom: 2rem;
    padding: 1rem;
    background: white;
    border: 1px solid $border;

    .CaseStudyFiles {
      display: grid;
      grid-gap: 0.5rem;

      > a {
        font-size: 1rem;
        font-weight: 500;
        text-decoration: underline;
        margin-right: auto;
      }
    }

  }

  .Response {
    > div {
      border: 1px solid $border;
      margin-bottom: 0.5em;
      display: grid;

      @include media-breakpoint-up(sm) {
        grid-gap: 1rem;
        margin-bottom: 1em;
        grid-template-columns: auto 1fr;
      }

      > div:first-child {
        border-bottom: 1px dashed $border;
        padding: 1rem;
        background: $light-grey-blue;

        @include media-breakpoint-up(sm) {
          border-bottom: 0;
          border-right: 1px dashed $border;
        }

        p,
        a {
          display: inline-block;
          font-size: 12px;
          margin-bottom: 0;
          margin-right: 0.3em;

          @include media-breakpoint-up(sm) {
            display: block;
            margin-right: 0;
          }
        }
      }

      > div:last-child {
        padding: 1rem;
        font-size: 12px;
      }
    }
  }

  .myResponse {
    > div {
      border-color: $grey-blue;
    }
  }

  .CaseStudyResponseForm {
    border: 1px dashed $border;
    padding: 1rem 1rem 0 1rem;
    margin-bottom: 2rem;
  }
}
