$background: #fafafa;
$border: #dedede;

$primary: #004791;
$secondary: #ef3c30;

$grey: #3d3d3d;
$grey-blue: #4b6b8c;
$light-grey-blue: #e7edf3;
$light-grey: #f1f1f1;
$bright-blue: #007bff;

$table-success: #cffdc0;
$table-warning: #fdcec0;
