.ForumThreadPage {
  .Post {
    border: 1px solid $border;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 0.5em;

    @include media-breakpoint-up(sm) {
      margin-bottom: 1em;
    }

    .user {
      border-bottom: 1px dashed $border;
      padding-bottom: 1em;
      margin-bottom: 1em;

      @include media-breakpoint-up(sm) {
        border-bottom: 0;
        border-right: 1px dashed $border;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      p,
      a {
        display: inline-block;
        font-size: 12px;
        margin-bottom: 0;
        margin-right: 0.3em;

        @include media-breakpoint-up(sm) {
          display: block;
          margin-right: 0;
        }
      }
    }

    .post {
      font-size: 12px;
    }
  }

  .ThreadPost {
    background: $light-grey-blue;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .Reply {
    background: white;
  }

  .ReplyForm {
    border: 1px dashed $border;
    padding-top: 1em;
  }
}
