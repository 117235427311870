.ChapterNavigation {
  border-bottom: 1px dashed $border;
  border-top: 1px dashed $border;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  background: $light-grey;

  .wrapper {
    @include media-breakpoint-up(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    }
  }

  p {
    display: inline-block;
    font-size: 1.2rem;
    margin-bottom: 0;

    &.next {
      @include media-breakpoint-up(sm) {
        text-align: right;
      }
    }

    &.split {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}
