.Breadcrumbs {
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid $border;

  * {
    display: inline-block;
  }

  p,
  a {
    font-size: 1.2em;
    margin-bottom: 0;
  }
}
