.Login {
  background-position: center;
  background-size: cover;
  height: 40rem;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(lg) {
    height: 50rem;
  }

  .LoginArea {
    background: rgba(255, 255, 255, 0.9);
    padding: 2rem;
    height: 100%;
    border-radius: 4px;
    border: 1px solid $primary;

    @include media-breakpoint-only(xs) {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    h2 {
      font-weight: 500;
      // color: $primary;
      margin-bottom: 1rem;
    }

    form {
      label {
        font-size: 1.4rem;
        font-weight: 400;
      }

      .field {
        &.checkbox {
          display: flex;
          align-items: center;
          // margin-bottom: 0.75rem;

          label {
            margin-bottom: 0;
          }
        }
      }

      input {
        &.text {
          width: 100%;
          padding: 0.3rem 0.75rem 0.1rem;
          font-size: 1.4rem;
          margin-bottom: 0.75rem;
        }
        &.checkbox {
          margin-right: 0.75rem;
        }
      }

      .btn-toolbar {
        margin-top: 2rem;
        padding-top: 2rem;
        border-top: 1px dashed $grey-blue;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 1rem;
        align-items: center;

        p {
          margin-bottom: 0;
          a {
            color: $primary;
          }
        }

        input {
          background: $grey-blue;
          border: none;
          width: 100%;
          font-size: 1.4rem;
          color: white;
          padding: 0.75rem;
          font-weight: 700;
          transition: background 0.2s;

          &:hover {
            background: $primary;
          }
        }
      }
    }
  }
}
