.EventPage {
  .Information {
    background: white;
    border: 1px solid $border;
    padding: 1rem;
    margin-bottom: 1rem;

    .Details {
      p {
        margin-bottom: 0;
      }
    }
  }

  .AttendActions {
    padding-bottom: 1rem;
  }
}
