.HomePage {
  .PageHeader {
    margin-top: 2rem;
  }
  .Threads,
  .Events,
  .CaseStudies {
    h5 {
      margin-bottom: 0;
    }
    p {
      font-size: 1.2rem;
      color: $grey;
    }

    table {
      margin-bottom: 2rem;
    }
  }
}
