// infused.io
// police medical officers

@import "~bootstrap/scss/bootstrap";

$hamburger-layer-width: 32px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 5px;
$hamburger-layer-color: #000;
$hamburger-layer-border-radius: 4px;
$hamburger-hover-opacity: 0.9;
@import "~hamburgers/_sass/hamburgers/hamburgers";
@import "~@grubersjoe/slide-menu/src/styles/slide-menu";
@import "Defaults/colors";
@import "Defaults/typography";
@import "Defaults/additions";
@import "Defaults/icons";

// media breakpoint example
// @include media-breakpoint-up(sm) {}

body {
  background: $background;

  .btn {
    font-weight: 600;
    font-size: 1.2em;
  }

  .btn-primary {
    background: $primary;
  }

  .PageHeader {
    font-weight: 600;
  }
}

@import "Includes/Header";
@import "Includes/Navigation/Mobile";
@import "Includes/Navigation/ChapterNavigation";
@import "Includes/Breadcrumbs";
@import "Includes/Table";
@import "Includes/Pagination";
@import "Includes/Login";
@import "Includes/Logout";
@import "Includes/LostPassword";

form {
  .form-group,
  .form-row,
  .field {
    label {
      font-size: 1.2em;
    }
    textarea,
    input,
    select {
      font-size: 1.2em;
    }
  }

  .field {
    margin-bottom: 0.5rem;
  }

  .btn-toolbar {
    input {
      font-weight: 600;
      font-size: 1.2em;
      background: $primary;

      border: 1px solid $bright-blue;
      border-radius: 0.25rem;
      padding: 0.375rem 0.75rem;
      color: white;
      transition: background 0.2s;

      &:hover {
        background-color: #0069d9;
        border-color: #0062cc;
      }
    }
  }
}

.PageWrapper {
  padding-bottom: 2rem;
}

.SearchForm {
  border-bottom: 1px solid $border;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  @include media-breakpoint-up(md) {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

// @import "Layouts/HomePage";
@import "Layout/Page";
@import "Layout/HomePage";
@import "Layout/CaseStudiesPage";
@import "Layout/CaseStudyPage";
@import "Layout/EventsPage";
@import "Layout/EventPage";
@import "Layout/ForumPage";
@import "Layout/ForumCategoryPage";
@import "Layout/ForumThreadPage";
@import "Layout/HandbookPage";
@import "Layout/HandbookChapterPage";
@import "Layout/MembershipPage";

@import "Includes/Footer";
@import "Includes/Copyright";
