.Logout {
  input {
    font-weight: 600;
    font-size: 1.2em;
    background: $primary;

    border: 1px solid $bright-blue;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    color: white;
    transition: background 0.2s;

    &:hover {
      background-color: #0069d9;
      border-color: #0062cc;
    }
  }
}
