.Header {
  padding: 1rem 0;
  background-color: $primary;
  // max-height: 9rem;

  h2 {
    color: white;
  }

  img {
    width: 100%;
  }

  @import "Navigation/Top";
}
