.slide-menu {
  background: $grey;
  border-left: 1px solid $border;

  ul {
    margin-top: 9em;

    // &:first-child {
    //   border-top: 1px solid #4c4c4c;
    // }

    a {
      color: $background;
      font-size: 1.6em;
      font-weight: 600;
      // text-align: right;

      padding: 1em 1.6em;
      border-bottom: 1px solid #4c4c4c;
      transition: background-color 0.2s;

      &:hover,
      &.current {
        color: $grey;
        text-decoration: none;
        background-color: $border;
      }
    }
  }
}

.menu-link {
  z-index: 9999;
  position: absolute;
  top: 1em;
  right: 1px;

  &:focus {
    outline: none;
  }

  .hamburger-inner {
    background-color: $background;

    &::before,
    &::after {
      background-color: $background;
    }
  }

  &.is-active {
    position: fixed;

    .hamburger-inner {
      background-color: $background;

      &::before,
      &::after {
        background-color: $background;
      }
    }
  }
}
