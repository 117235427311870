.table {
  background: white;
  border: 1px solid $border;

  p,
  a,
  th,
  td {
    font-size: 12px;
  }

  tbody {
    tr {
      :not(:last-child) {
        border-right: 1px dashed $border;
      }
      td.empty {
        background: $light-grey;
      }
    }
  }
}
