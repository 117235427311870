.Pagination {
  border-top: 1px solid $border;
  padding-top: 1em;

  * {
    display: inline-block;
  }

  a,
  p {
    font-size: 1.2em;
    font-weight: 600;
    padding: 0.25em 0.5em;
    border: 1px solid $border;
    background: white;
    transition: background 0.2s;
  }

  a {
    &:hover {
      background: $border;
      text-decoration: none;
    }
  }
}
