.LostPassword {
  form {
    label {
      font-size: 1.4rem;
      font-weight: 500;
    }

    .field {
      &.checkbox {
        display: flex;
        align-items: center;
        // margin-bottom: 0.75rem;

        label {
          margin-bottom: 0;
        }
      }
    }

    input {
      &.text {
        width: 100%;
        padding: 0.3rem 0.75rem 0.1rem;
        font-size: 1.4rem;
        margin-bottom: 0.75rem;
      }
    }

    .btn-toolbar {
      input {
        font-weight: 600;
        font-size: 1.2em;
        background: $primary;

        border: 1px solid $bright-blue;
        border-radius: 0.25rem;
        padding: 0.375rem 0.75rem;
        color: white;
        transition: background 0.2s;

        &:hover {
          background-color: #0069d9;
          border-color: #0062cc;
        }
      }
    }
  }
}
